// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 700px) {
  .footer_container__k0eHF .footer_boxContainer__AZOFL {
    padding-top: 70px;
  }

  .footer_container__k0eHF .footer_footerFlex__8LiH2 {
    margin: 50px 0;
    flex-wrap: wrap !important;
    gap: 45px;
    flex-direction: row;
    justify-content: flex-start;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/LandingPage/components/footer.module.scss"],"names":[],"mappings":"AAGI;EACI;IACI,iBAAA;EAFV;;EAKM;IACI,cAAA;IACA,0BAAA;IACA,SAAA;IACA,mBAAA;IACA,2BAAA;EAHV;AACF","sourcesContent":[".container {\n\n    // border: 1px solid red;\n    @media screen and (max-width: 700px) {\n        .boxContainer {\n            padding-top: 70px;\n        }\n\n        .footerFlex {\n            margin: 50px 0;\n            flex-wrap: wrap !important;\n            gap: 45px;\n            flex-direction: row;\n            justify-content: flex-start;\n\n\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `footer_container__k0eHF`,
	"boxContainer": `footer_boxContainer__AZOFL`,
	"footerFlex": `footer_footerFlex__8LiH2`
};
export default ___CSS_LOADER_EXPORT___;
